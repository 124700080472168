var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MoreInfo'),_c('div',{staticClass:"mx-7"},[_c('v-card',[_c('v-list-item-content',[_c('v-card-text',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.affiliate.name))]),_vm._v(" | Archivos y Radicados")])],1)],1)],1),_c('v-row',{staticClass:"mx-4 my-2"},[_c('v-col',{staticStyle:{"min-height":"200px"},attrs:{"cols":"12","xl":"3","lg":"4","md":"4","sm":"6"}},[_c('v-card',{staticStyle:{"height":"100%","cursor":"pointer"},on:{"click":_vm.uploadFile}},[_c('v-list-item',{staticStyle:{"height":"100%"},attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"50px"}},[_vm._v(" mdi-plus-circle-outline ")])],1)],1)],1)],1)],1),_vm._l((_vm.files),function(file){return _c('v-col',{key:file._id,attrs:{"cols":"12","xl":"3","lg":"4","md":"4","sm":"6"}},[_c('v-card',[_c('v-card-actions',{staticClass:"my-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-4 mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.downloadFile(file._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,true)},[_c('span',[_vm._v("Descargar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-4 mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.editFile(file._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Cambiar Nombre")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-4 mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.deleteFile(file._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',{staticStyle:{"height":"100%","cursor":"pointer"},on:{"click":function($event){return _vm.downloadFile(file._id)}}},[_c('v-list-item-title',{staticClass:"text-center"},[(file.extension == 'PDF')?_c('v-icon',{attrs:{"size":"50px"}},[_vm._v(" mdi-file-pdf-box ")]):_c('v-icon',{attrs:{"size":"50px"}},[_vm._v(" mdi-image ")])],1),_c('v-card-text',{staticClass:"text-center"},[_vm._v(_vm._s(file.name))])],1)],1)],1)],1)})],2),_c('div',{staticClass:"mx-7"},[_c('v-card',[_c('v-list-item-content',[_c('v-card-text',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.affiliate.name))]),_vm._v(" | Planillas")])],1)],1)],1),_c('v-row',{staticClass:"mx-4 my-2"},_vm._l((_vm.collections),function(collection){return _c('v-col',{key:collection._id,attrs:{"cols":"12","xl":"3","lg":"4","md":"4","sm":"6"}},[_c('v-card',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadPayRoll(collection._id)}}},[_c('v-card-actions',{staticClass:"my-0",staticStyle:{"opacity":"0"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"my-3 mx-5",attrs:{"icon":""}},[_c('v-icon')],1)],1)],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"50px"}},[_vm._v(" mdi-file-pdf-box ")])],1),_c('v-card-text',{staticClass:"text-center"},[_vm._v(_vm._s(collection.monthName)+" "+_vm._s(collection.year)+".pdf")])],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
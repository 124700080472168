<template>
    <div>
        <MoreInfo></MoreInfo>
        <div class="mx-7">
            <v-card>
                <v-list-item-content>
                    <v-card-text class="text-subtitle-1"><span class="font-weight-bold">{{ affiliate.name }}</span> | Archivos y Radicados</v-card-text>
                </v-list-item-content>
            </v-card>
        </div>

        <v-row class="mx-4 my-2">
            <v-col cols="12" xl="3" lg="4" md="4" sm="6" style="min-height: 200px;">
                <v-card style="height: 100%; cursor: pointer;" @click="uploadFile">
                    <v-list-item style="height: 100%;" three-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            <v-icon size="50px">
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" xl="3" lg="4" md="4" sm="6" v-for="file in files" :key="file._id">
                <v-card>
                    <v-card-actions class="my-0">
                        <v-row justify="end">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mt-4 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="downloadFile(file._id)"
                                    >
                                        mdi-download
                                    </v-icon>
                                </template>
                                <span>Descargar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mt-4 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="editFile(file._id)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span>Cambiar Nombre</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mt-4 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="deleteFile(file._id)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </v-row>
                    </v-card-actions>
                    <v-list-item three-line>
                    <v-list-item-content style="height: 100%; cursor: pointer;" @click="downloadFile(file._id)">
                        <v-list-item-title class="text-center">
                            <v-icon v-if="file.extension == 'PDF'" size="50px">
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else size="50px">
                                mdi-image
                            </v-icon>
                        </v-list-item-title>
                        <v-card-text class="text-center">{{ file.name }}</v-card-text>
                    </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>

        <div class="mx-7">
            <v-card>
                <v-list-item-content>
                    <v-card-text class="text-subtitle-1"><span class="font-weight-bold">{{ affiliate.name }}</span> | Planillas</v-card-text>
                </v-list-item-content>
            </v-card>
        </div>

        <v-row class="mx-4 my-2">
            <v-col cols="12" xl="3" lg="4" md="4" sm="6" v-for="collection in collections" :key="collection._id">
                <v-card style="cursor: pointer;" @click="downloadPayRoll(collection._id)">
                    <v-card-actions class="my-0" style="opacity: 0">
                        <v-row justify="end">
                            <v-btn
                                class="my-3 mx-5"
                                icon
                            >
                            <v-icon>
                            </v-icon>
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                    <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            <v-icon size="50px">
                                mdi-file-pdf-box
                            </v-icon>
                        </v-list-item-title>
                        <v-card-text class="text-center">{{ collection.monthName }} {{ collection.year }}.pdf</v-card-text>
                    </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import Storage from "@/modules/storage";
import MoreInfo from '@/components/Layout/MoreInfo.vue'

const store = new Storage();

export default {
    data() {
        return {
           affiliate: {},
           collections: [],
           files: []
        }
    },
    components: {
        MoreInfo
    },
    async mounted() {
        this.affiliate = await store.getData("affiliate", this.$route.params.object_id);
        this.files = [];
        this.collections = [];

        const collections = await store.getData("collection");
        const files = await store.getData("filem");

        files.forEach(file => {
            if (file.affiliate == this.$route.params.object_id) {
                this.files.push(file);
            }
        });
        collections.forEach(collection => {
            if (collection.payrollCode && collection.affiliate == this.$route.params.object_id) {
                if (collection.month == 1) {
                    collection.monthName = 'Enero';
                } else if (collection.month == 2) {
                    collection.monthName = 'Febrero';
                } else if (collection.month == 3) {
                    collection.monthName = 'Marzo';
                } else if (collection.month == 4) {
                    collection.monthName = 'Abril';
                } else if (collection.month == 5) {
                    collection.monthName = 'Mayo';
                } else if (collection.month == 6) {
                    collection.monthName = 'Junio';
                } else if (collection.month == 7) {
                    collection.monthName = 'Julio';
                } else if (collection.month == 8) {
                    collection.monthName = 'Agosto';
                } else if (collection.month == 9) {
                    collection.monthName = 'Septiembre';
                } else if (collection.month == 10) {
                    collection.monthName = 'Octubre';
                } else if (collection.month == 11) {
                    collection.monthName = 'Noviembre';
                } else if (collection.month == 12) {
                    collection.monthName = 'Diciembre';
                }
                this.collections.push(collection);
            }
        });
    },
    methods: {
        downloadPayRoll: function (_id) {
            const URL = store.baseURL + "/misalud/collection/download?where=_id=" + _id;
            axios.get(URL, { withCredentials: true }).then(res => {
                window.open(res.data.body.URL);
            });
        },
        downloadFile: function (_id) {
            const URL = store.baseURL + "/misalud/filem/download?where=_id=" + _id;
            axios.get(URL, { withCredentials: true }).then(res => {
                window.open(res.data.body.URL);
            });
        },
        uploadFile: function() {
            this.$router.push({ name: "Affiliate Upload File", params: {
                object_id: this.$route.params.object_id
            }});
        },
        async editFile(_id) {
            const file = await store.getData("filem", _id);
            const data = {};
            Swal.fire({
                title: 'Nuevo Nombre de Archivo',
                input: 'text',
                inputLabel: file.name,
                inputValidator: async (value) => {
                    if (value) {
                        Swal.fire({
                            icon: "warning",
                            title: "Actualizando Nombre de Archivo",
                            html:
                            "Por favor espere mientras se actualiza el Archivo</b> " +
                            '<progress id="progressBar" value="0" max="100"></progress>',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            stopKeydownPropagation: true
                        });

                        data._id = file._id;
                        data.name = value;
                        await store.updateData("filem", [data], true, "misalud");

                        Swal.fire({
                            icon: "success",
                            title: "Archivo actualizado"
                        }).then(() => {
                            this.$router.go();
                        });
                    }
                }
            });
        },
        async deleteFile(_id) {
            const file = await store.getData("filem", _id);
            Swal.fire({
                title: "¿Eliminar Archivo?",
                text: file.name,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#dc3545",
                cancelButtonColor: "#6c757d"
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: "warning",
                        title: "Eliminando Archivo",
                        html:
                            "Por favor espere mientras se elimina el Archivo</b> " +
                            '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        stopKeydownPropagation: true
                    });
                    store.deleteData("misalud", "filem", _id).then(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Archivo Eliminado",
                            text: "Todos los datos han sido sincronizados"
                        })
                        this.files.forEach((item, index) => {
                            if (item._id === _id) {
                                this.files.splice(index, 1);
                            }
                        });
                    });
                }
            });
        }
    },
}
</script>